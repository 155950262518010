import React from 'react';
import { CheckerWidget } from '@vyce/core/src/components/CheckerWidget';

export const RtwWidget: React.FC = () => {
  return (
    <CheckerWidget
      title="Right To Work unverified"
      subtitle="Verifying your RTW is important for any companies you work with on Vyce and will enable you to work and be paid through Vyce"
      redirectTo="/profile/documents"
      type="warning"
      buttonText="Go to profile to verify"
    />
  );
};
