import React, { useEffect, useState } from 'react';
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { CheckerWidget } from "@vyce/core/src/components/CheckerWidget";

export const QualificationsWidget: React.FC = () => {
  const { worker } = useTypedSelector((state) => state.user);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const qualifications = worker?.qualifications;
    if (qualifications) {
      const coursesCount = qualifications?.courses?.items?.length || 0;
      const testsCount = qualifications?.tests?.items?.length || 0;
      const certificates = qualifications?.certificates?.items?.length || 0;
      setCount(coursesCount + testsCount + certificates);
    }
  }, [worker]);


  return (
    <CheckerWidget
      subtitle="Adding more qualifications will help you to get more relevant jobs"
      redirectTo="/profile/qualifications"
      buttonText="Add more qualifications"
      title={`${count} qualification${count === 1 ? '' : 's'} verified`}/>
  );
};
