import React, { useContext, useEffect, useState } from 'react';

import { Layouts } from 'react-grid-layout';
import { Box } from '@material-ui/core';

import { WidgetItem } from '@vyce/core/src/types';
import { getFromLS, saveToLS } from '@vyce/core/src/utils/local-storage';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { EmailVerificationWidget } from '@vyce/core/src/views/widgets/EmailVerificationWidget';
import { AppDashboard, getInitialLayouts } from '@vyce/core/src/components/Dashboard/AppDashboard';
import { useRecaptcha } from '@vyce/core/src/hooks';
import { ReCAPTCHAComponent } from '@vyce/core/src/components';

import { useTypedSelector } from '../../hooks';
import { QualificationsWidget } from './components/QualificationsWidget';
import { RtwWidget } from './components/RtwWidget';
import { PassportIDWidget } from './components/PassportIDWidget';

export const WORKER_DASHBOARD_ITEMS: WidgetItem[] = [
  {
    name: 'Verify your Email',
    system_name: 'email',
    type: 'email',
    layout: { i: 'email', x: 0, y: 0, w: 2, h: 5, minH: 4, minW: 2 },
    is_active: true,
  },
  {
    name: 'Right To Work',
    type: 'rtw',
    system_name: 'rtw',
    layout: { i: 'rtw', x: 2, y: 0, w: 2, h: 5, minH: 4, minW: 2 },
    is_active: true,
  },
  {
    name: 'Passport',
    type: 'passport',
    system_name: 'passport',
    layout: { i: 'passport', x: 4, y: 0, w: 2, h: 5, minH: 4, minW: 2 },
    is_active: true,
  },
  {
    name: 'Qualifications',
    type: 'qualification',
    system_name: 'qualification',
    layout: { i: 'qualification', x: 6, y: 5, w: 2, h: 5, minH: 4, minW: 2 },
    is_active: true,
  },
];

const LS_ITEMS_KEY = 'dashboardItems';
const LS_LAYOUT_KEY = 'dashboardLayouts';

export const Dashboard: React.FC = () => {
  const [items, setItems] = useState<WidgetItem[]>(getFromLS(LS_ITEMS_KEY) || WORKER_DASHBOARD_ITEMS);
  const [layouts, setLayouts] = useState<Layouts>(
    getFromLS(LS_LAYOUT_KEY) || getInitialLayouts(WORKER_DASHBOARD_ITEMS)
  );
  const { isMobile } = useContext(DeviceContext);
  const { user } = useTypedSelector(state => state);
  const { reCaptchaRef, getReCaptchaToken } = useRecaptcha();

  useEffect(() => {
    let items = WORKER_DASHBOARD_ITEMS;
    if (user.id_verification?.verified) {
      items = items.filter(item => item.type !== 'passport');
    }
    if (user.rtw_verification?.verified) {
      items = items.filter(item => item.type !== 'rtw');
    }
    if (user.email_verified) {
      items = items.filter(item => item.type !== 'email');
    }
    setItems(items);
  }, [user]);

  const onLayoutChange = (_: any, allLayouts: Layouts) => {
    setLayouts(allLayouts);
    saveToLS(LS_LAYOUT_KEY, allLayouts);
    saveToLS(LS_ITEMS_KEY, items);
  };

  const onWidgetStatusChange = (itemId: string) => {
    const newItems = items.map(item => {
      if (item.name === itemId) {
        return { ...item, isActive: !item.is_active };
      }
      return item;
    });
    setItems(newItems);
  };

  const getWidget = (item: WidgetItem) => {
    switch (item.type) {
      case 'qualification':
        return <QualificationsWidget />;
      case 'rtw':
        return <RtwWidget />;
      case 'passport':
        return <PassportIDWidget />;
      case 'email':
        return <EmailVerificationWidget userId={user.uuid} getReCaptchaToken={getReCaptchaToken} />;
    }
  };

  return (
    <Box padding={isMobile ? 1 : 2}>
      <AppDashboard
        widgets={items}
        onWidgetStatusChange={onWidgetStatusChange}
        getWidget={getWidget}
        layouts={layouts}
        onLayoutChange={onLayoutChange}
      />
      <ReCAPTCHAComponent ref={reCaptchaRef} />
    </Box>
  );
};
