import React from 'react';
import { CheckerWidget } from '@vyce/core/src/components/CheckerWidget';

export const PassportIDWidget: React.FC = () => {
  return (
    <CheckerWidget
      title="Passport unverified"
      subtitle="Verifying your Passport (ID) is important for any companies you work with on Vyce and will enable you to work and be paid through Vyce"
      redirectTo="/profile/documents"
      type="warning"
      buttonText="Go to profile to verify"
    />
  );
};
